import { faBullhorn, faChartBar, faDonate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { useState } from 'react';
import { /*Button,*/ Container, /*Form, FormControl,*/ Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {
  BrowserRouter as Router, Link, Route, Routes
} from "react-router-dom";
import './Routers.css';


// Pages
import About from './Pages/About';
import Donates from './Pages/Donates';
import Footer from './Pages/Footer';
import Home from './Pages/Home';
import NotFound from './Pages/NotFound';
//import StatisticDetail from './Pages/StatisticDetail';
import Statistics from './Pages/Statistics';
import Supports from './Pages/Supports';


// Analytics
import { Analytics } from '@vercel/analytics/react';


function Routers() {
  //const [search, setSearch] = useState("");
  
  return (
      <>
      <Router>
        <Navbar bg="dark" fixed="top" variant="dark" expand="lg">
          <Container fluid>
            <Link to="/">
              <Navbar.Brand>
                <FontAwesomeIcon icon={faBullhorn}/>{' '}Kırklareli Üniversitesi Duyuru
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '260px',  }} navbarScroll>
              <Link to="/donates"><Nav.Link href="/donates"><FontAwesomeIcon icon={faDonate}/>{' '}Yardımlar</Nav.Link></Link>
              <Link to="/statistics"><Nav.Link href="/statistics"> <FontAwesomeIcon icon={faChartBar}/>{' '} İstatistikler</Nav.Link></Link>

              <NavDropdown title="Hakkında" id="navbarScrollingDropdown">
                <Link to="/about"><NavDropdown.Item href="/about">Hakkında</NavDropdown.Item></Link>
                <Link to="/supports"><NavDropdown.Item href="/supports">Destekleyenler</NavDropdown.Item></Link>
                {/*<NavDropdown.Divider />
                <NavDropdown.Item href="#action5">Öneri</NavDropdown.Item>*/}
              </NavDropdown>
            </Nav>
            {/*<Form className="d-flex">
              <FormControl style={{width:'320px'}}
              type="search"
              placeholder="Şu an çalışmıyorum. Süs olsun diye varım"
              className="me-2"
              aria-label="Search"
              value={search}
              onChange={({target:{value}})=>setSearch(value)}
              />
              <Button
              variant="outline-success"
              onClick={()=>console.log(search)}
              >Arayamazsın ki :'(</Button>
            </Form>*/}
          </Navbar.Collapse>
          </Container>
        </Navbar>
      <div className="Div-Routers">
        <Routes>
          <Route exact path="/" element={<Home/>}></Route>
          <Route path="/donates" element={<Donates/>}></Route>
          {/*<Route path="/statistics/:id" render={props=><StatisticDetail {...props}/>}/>*/}
          <Route path="/statistics" element={<Statistics/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/supports" element={<Supports/>}></Route>
          <Route default element={<NotFound/>}/>
        </Routes>
      </div>
    </Router>
    <Footer/>
    <Analytics />
    </>
    );
}

export default Routers;