import './NotFound.css';

function NotFound(){
    return(
        <>
        <div className="Div-NotFound">
            <h1>Not Found</h1>
        </div>
        </>
    );
}

export default NotFound;