import './Statistics.css';

function Statistics(){
    return(
        <>
            <div className="Div-Statistics">
                <h1>İstatistikler</h1>
                <p>Merhaba, Bu sayfada internet sitesi, Telegram, Instagram ve Discord istatistiklerini anlık (tabi mümkün olabilirse) olarak görmenizi sağlayacak sayfa yer alacaktır.</p>
                <p><a href="https://app.splitbee.io/public/kluduyuru.ramazansancar.com.tr" target="_blank" rel="noopener noreferrer">Web Site İstatistikleri</a></p>
            </div>
        </>
    );
}

export default Statistics;
