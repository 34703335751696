import { Navbar } from 'react-bootstrap';

import './Footer.css';

function Footer() {
    return (
      <div className="Footer">
        <Navbar bg="dark" fixed="bottom" variant="dark" expand="lg">
          <div className="color-white Copyright">Powered by <a href="https://www.ramazansancar.com.tr/?utm_source=website&utm_medium=footer&utm_campaign=kluduyuru" target="_blank" rel="noopener noreferrer">Ramazan Sancar</a> © 2023</div>
        </Navbar>
      </div>
    );
}
  
export default Footer;