import { Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram, faDiscord, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faAt, faBullhorn } from '@fortawesome/free-solid-svg-icons';

import './Home.css';

function Home() {
    return (
      <div className="Home">
        <header className="Home-header">
          <p>
            Burası Ana sayfa <code>Bir şeyler bozuldu galiba :')</code> <br/> <code>Şaka şaka hadi biraz etrafa bakın :)</code>
          </p>
          <a className="App-link" href="https://whatsapp.com/channel/0029Va5Fn7qH5JM7sTUa8t1X?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer">
            <Button variant="success" size="lg">
              <FontAwesomeIcon icon={faWhatsapp}/>{' '}<Badge bg="info">En Yeni!</Badge> Whatsapp Kanalı
            </Button>
          </a>
          {' '}
          <a className="App-link" href="https://discord.gg/jsTUuHfUZw?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer">
            <Button variant="info" size="lg">
              <FontAwesomeIcon icon={faDiscord}/>{' '}<Badge bg="success">En Yeni!</Badge> Discord
            </Button>
          </a>
          {' '}
          <a className="App-link" href="https://t.me/kluhaberlesme?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer">
            <Button variant="outline-primary" size="lg">
              <FontAwesomeIcon icon={faTelegram}/>{' '}<Badge bg="success">Yeni</Badge> Telegram Haberleşme grubuna katılmak için tıklayın
            </Button>
          </a>
          {' '}
          <a className="App-link" href="https://t.me/kirklarelibulten?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer">
            <Button variant="outline-primary" size="lg">
              <FontAwesomeIcon icon={faBullhorn}/>{' '}Kırklareli Bülten'e katılmak için tıklayın
            </Button>
          </a>
          {' '}
          <a className="App-link" href="https://t.me/kluduyuru?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer">
            <Button variant="outline-primary" size="lg">
              <FontAwesomeIcon icon={faTelegram}/>{' '}Telegram grubuna katılmak için tıklayın
            </Button>
          </a>
          {' '}
          <a className="App-link" href="https://t.me/s/kluduyuru?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer">
            <Button variant="outline-primary" size="lg">
              <FontAwesomeIcon icon={faTelegram}/>{' '}Telegram paylaşımlarımıza bakmak için tıklayın
            </Button>
          </a>
          {' '}
          <a className="App-link" href="https://www.instagram.com/kluduyuru/?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer">
            <Button variant="outline-primary" size="lg">
              <FontAwesomeIcon icon={faInstagram}/>{' '}Instagram'da takip etmek için tıklayın
            </Button>
          </a>
          {' '}
          <a className="App-link" href="mailto:kluduyuru@ramazansancar.com.tr?subject=Selam, proje ile ilgili sohbet edelim mi?" target="_blank" rel="noopener noreferrer">
            <Button variant="outline-primary" size="lg">
              <FontAwesomeIcon icon={faAt}/>{' '} Bir mail uzağınızdayız, çekinmeyin :)
            </Button>
          </a>
        </header>
      </div>
    );
}
  
export default Home;
