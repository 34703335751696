import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faInstagram, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

import './About.css';

function About(){
    return(
        <>
        <div className="Div-About">
            <h1>Hakkında</h1>
            <p>KLU Duyuru projesi, proje sorumluluk amacıyla oluşturulmuş. Ve bu şekilde devam etmesi amaçlanmaktadır. Projeden elde edilen fonun tamamı projeyi geliştirmek veya sosyal sorumluluk projelerini desteklemek için kullanılacaktır. Ve tüm gelir, gider kayıtları sitemizin <Link to="/supports">Destekleyenler</Link> sayfasında yer alacaktır.</p>
            <hr/>
            <p>Projeyi desteklemek isterseniz geri bildirimde bulunmanız ve takip etmeniz yeterlidir. Teşekkürler :)</p>
            <a href="https://t.me/kluduyuru?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer"><Button variant="primary"><FontAwesomeIcon icon={faTelegram}/>{' '}Telegram</Button></a>
            {' '}
            <a href="https://www.instagram.com/kluduyuru/?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer"><Button variant="primary"><FontAwesomeIcon icon={faInstagram}/>{' '}Instagram</Button></a>
            {' '}
            <a href="https://discord.gg/jsTUuHfUZw?utm_source=kluduyuru-web" target="_blank" rel="noopener noreferrer"><Button variant="primary"><FontAwesomeIcon icon={faDiscord}/>{' '}Discord</Button></a>
            {' '}
            <a href="mailto:kluduyuru@ramazansancar.com.tr?subject=Selam, proje ile ilgili sohbet edelim mi?" target="_blank" rel="noopener noreferrer"><Button variant="primary"><FontAwesomeIcon icon={faAt}/>{' '}Mail</Button></a>
            
        </div>
        </>
    );
}

export default About;