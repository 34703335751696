import { ListGroup, Badge, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Supports.css';

function Supports(){
    return(
        <>
        <div className="Div-Supports">
            <h1>Destekleyenler</h1>
            <p>Projeye destek olanlar aşağıda yer almaktadır.</p>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    Ramazan Sancar{' '}<Badge bg="info">Proje Yazılımcısı ve Oluşturucusu</Badge>
                </ListGroup.Item>
                {/*}
                <ListGroup.Item>
                    Dr.Öğr.Ü. Bora Aslan{' '}<Badge bg="info">Danışman Öğretim Görevlisi</Badge>
                </ListGroup.Item>
                <ListGroup.Item>-</ListGroup.Item>
                <ListGroup.Item>-</ListGroup.Item>*/}
            </ListGroup>
            <hr/>
            <div className="Supports-list">
                <Card style={{ width: '17rem' }}>
                    <Card.Img variant="top" src="/images/ramazan_sancar.jpg" />
                    <Card.Body>
                        <Card.Title className="color-black">Ramazan Sancar{' '}<Badge bg="info">Proje Yazılımcısı ve Oluşturucusu</Badge></Card.Title>
                        <Card.Text className="color-black">
                        Bir akşam saatinde yarın ne yemek var? sorusuna cevap bulması üzerine ortaya çıkan projedir.
                        </Card.Text>
                        <a href="https://www.linkedin.com/in/ramazansancar/" target="_blank" rel="noopener noreferrer"><Button variant="primary"><FontAwesomeIcon icon={faLinkedin}/>{' '}Linkedin</Button></a>
                    </Card.Body>
                </Card>
                {/*
                <Card style={{ width: '17rem' }}>
                    <Card.Img variant="top" src="/images/bora_aslan.jpg" />
                    <Card.Body>
                        <Card.Title className="color-black">Dr.Öğr.Ü. Bora Aslan{' '}<Badge bg="info">Danışman Öğretim Görevlisi</Badge></Card.Title>
                        <Card.Text className="color-black">
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '17rem' }}>
                    <Card.Img variant="top" src="holder.js/100px180" />
                    <Card.Body>
                        <Card.Title className="color-black">-{' '}<Badge bg="info">-</Badge></Card.Title>
                        <Card.Text className="color-black">
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>
                <Card style={{ width: '17rem' }}>
                    <Card.Img variant="top" src="holder.js/100px180" />
                    <Card.Body>
                        <Card.Title className="color-black">-{' '}<Badge bg="info">-</Badge></Card.Title>
                        <Card.Text className="color-black">
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>*/}
            </div>
        </div>
        </>
    );
}

export default Supports;